var menuData = [{
		component_path: "admin/base/home.vue",
		icon: require('@/assets/images/860.png'),
		menu_id: 1,
		name: "Home",
		path: "/admin/Home/index.html",
		pid: 0,
		sortid: 1,
		title: "首页",
		children: [{
				component_path: "admin/base/home.vue",
				icon: require('@/assets/images/860.png'),
				menu_id: 1,
				name: "Home",
				path: "/admin/Home/index.html",
				pid: 0,
				sortid: 1,
				title: "数据概览",
			},
			{
				component_path: "admin/map/index.vue",
				icon: require('@/assets/images/860.png'),
				menu_id: 1,
				name: "Map",
				path: "/admin/Map/index.html",
				pid: 0,
				sortid: 1,
				title: "门店概览",
			},

		]
	},

	{
		component_path: "admin/store/index.vue",
		icon: require('@/assets/images/861.png'),
		menu_id: 2,
		name: "Store",
		path: "/admin/Store/index.html",
		pid: 0,
		sortid: 1,
		title: "门店管理",
		children: [{
				component_path: "admin/store/index.vue",
				menu_id: 2 - 1,
				name: "Store",
				path: "/admin/Store/index.html",
				pid: 2,
				sortid: 1,
				title: "门店列表",
			},
			{
				children: [],
				component_path: "admin/walker/index.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "Walker",
				path: "/admin/Walker/index.html",
				pid: 10,
				sortid: 9,
				title: "店东账号",
			},
			{
				component_path: "admin/transaction/index.vue",
				menu_id: 2 - 1,
				name: "Transaction",
				path: "/admin/Transaction/index.html",
				pid: 2,
				sortid: 1,
				title: "门店交易",
				// meta: {
				// 	keepAlive: true // 需要缓存的组件添加meta字段，值为true
				// }
			},
			{
				component_path: "admin/store/account.vue",
				menu_id: 2 - 1,
				name: "account",
				path: "/admin/Store/account.html",
				pid: 2,
				sortid: 1,
				title: "门店账户",
			},
			{
				component_path: "admin/rewards/index.vue",
				menu_id: 2 - 1,
				name: "Rewards",
				path: "/admin/Rewards/index.html",
				pid: 2,
				sortid: 1,
				title: "奖惩记录",
			},
			// {
			//    component_path: "admin/complaint/index.vue",
			//    menu_id: 2-1,
			//    name: "Complaint",
			//    path: "/admin/Complaint/index.html",
			//    pid: 2,
			//    sortid: 1,
			//    title: "客诉记录",
			// }, 

			{
				component_path: "admin/cost/index.vue",
				menu_id: 2 - 1,
				name: "Cost",
				path: "/admin/Cost/index.html",
				pid: 2,
				sortid: 1,
				title: "费用管理",
			},
		],
	},
	{
		component_path: "admin/complaint/index.vue",
		icon: require('@/assets/images/ks.png'),
		menu_id: 3,
		name: "Complaint",
		path: "/admin/Complaint/index.html",
		pid: 0,
		sortid: 1,
		title: "客诉中心",
		children: [{
				component_path: "admin/complaint/index.vue",
				menu_id: 3 - 1,
				name: "Complaint",
				path: "/admin/Complaint/index.html",
				pid: 3,
				sortid: 1,
				title: "客诉列表",
			},
			{
				component_path: "admin/complaint/complaintCount.vue",
				menu_id: 3 - 1,
				name: "complaintCount",
				path: "/admin/Complaint/complaintCount.html",
				pid: 3,
				sortid: 1,
				title: "客诉统计",
			},
			{
				component_path: "admin/complaint/complaintFormSet.vue",
				menu_id: 3 - 1,
				name: "complaintFormSet",
				path: "/admin/Complaint/complaintFormSet.html",
				pid: 3,
				sortid: 1,
				title: "投诉形式设置",
			},
			{
				component_path: "admin/complaint/complaintClsSet.vue",
				menu_id: 3 - 1,
				name: "complaintClsSet",
				path: "/admin/Complaint/complaintClsSet.html",
				pid: 3,
				sortid: 1,
				title: "投诉分类设置",
			},
			{
				component_path: "admin/complaint/complaintRemindSet.vue",
				menu_id: 3 - 1,
				name: "complaintRemindSet",
				path: "/admin/Complaint/complaintRemindSet.html",
				pid: 3,
				sortid: 1,
				title: "客诉提醒设置",
			},
		],
	},
	{
		component_path: "admin/type/index.vue",
		icon: require('@/assets/images/863.png'),
		menu_id: 3,
		name: "Type",
		path: "/admin/Type/index.html",
		pid: 0,
		sortid: 1,
		title: "公告管理",
		children: [{
				component_path: "admin/type/index.vue",
				menu_id: 3 - 1,
				name: "Type",
				path: "/admin/Type/index.html",
				pid: 3,
				sortid: 1,
				title: "公告列表",
			},
			// {
			//    component_path: "admin/punishment/index.vue",
			//    menu_id: 3-1,
			//    name: "Punishment",
			//    path: "/admin/Punishment/index.html",
			//    pid: 3,
			//    sortid: 1,
			//    title: "奖惩公告",
			// },
		],
	},
	{
		component_path: "admin/goods/index.vue",
		icon: require('@/assets/images/icon8.png'),
		menu_id: 6,
		name: "Goods",
		path: "/admin/Goods/index.html",
		pid: 0,
		sortid: 1,
		title: "合同管理",
		children: [{
			children: [],
			component_path: "admin/contract/index.vue",
			icon: "el-icon-menu",
			menu_id: 6 - 1,
			name: "Contract",
			path: "/admin/Contract/index.html",
			pid: 6,
			sortid: 1,
			title: "加盟合同",
		}, ],
	},
	{
		component_path: "/",
		icon: require('@/assets/images/864.png'),
		menu_id: 8,
		name: "/",
		path: "/",
		pid: 0,
		sortid: 1,
		title: "推荐加盟",
		children: [{
				children: [],
				component_path: "admin/nav/index.vue",
				icon: "el-icon-menu",
				menu_id: 8 - 1,
				name: "Nav",
				path: "/admin/Nav/index.html",
				pid: 8,
				sortid: 9,
				title: "报备列表",
			},
			// {
			//     children: [],
			//     component_path: "admin/nav/order.vue",
			//     icon: "el-icon-menu",
			//     menu_id: 8-1,
			//     name: "NavOrder",
			//     path: "/admin/NavOrder/index.html",
			//     pid: 8,
			//     sortid: 9,
			//     title: "加盟政策",
			// },

		],
	},
	{
		component_path: "admin/expense/shop.vue",
		icon: require('@/assets/images/868.png'),
		menu_id: 1,
		name: "ExpenseShop",
		path: "/admin/ExpenseShop/index.html",
		pid: 0,
		sortid: 1,
		title: "消息审核",
		children: [

			{
				component_path: "admin/expense/index.vue",
				menu_id: 1,
				name: "Expense",
				path: "/admin/Expense/index.html",
				pid: 0,
				sortid: 1,
				title: "审核列表",

			},
			{
				children: [],
				icon: require('@/assets/images/870.png'),
				component_path: "admin/feedback/index.vue",
				menu_id: 13 - 1,
				name: "Feedback",
				path: "/admin/Feedback/index.html",
				pid: 5,
				sortid: 1,
				title: "消息反馈",
			},
			// {
			// 	component_path: "admin/ledger/index.vue",
			// 	menu_id: 1,
			// 	name: "Ledger",
			// 	path: "/admin/Ledger/index.html",
			// 	pid: 0,
			// 	sortid: 1,
			// 	title: "二手房账单",

			// },
			{
				component_path: "admin/ledger/application.vue",
				menu_id: 1,
				name: "ownerA",
				path: "/admin/ownerA/index.html",
				pid: 0,
				sortid: 1,
				title: "店东申请",

			},

			{
				component_path: "admin/abnormal/index.vue",
				menu_id: 1,
				name: "Abnormal",
				path: "/admin/Abnormal/index.html",
				pid: 0,
				sortid: 1,
				title: "异常分账",

			},
			// {
			// 	component_path: "admin/abnormal/cash.vue",
			// 	menu_id: 1,
			// 	name: "Acash",
			// 	path: "/admin/Acash/index.html",
			// 	pid: 0,
			// 	sortid: 1,
			// 	title: "收款审核记录",

			// },

		]
	},

	{
		component_path: "admin/manager/index.vue",
		icon: require('@/assets/images/862.png'),
		menu_id: 6,
		name: "Manager",
		path: "/admin/Manager/index.html",
		pid: 0,
		sortid: 1,
		title: "运营管理",
		children: [{
				children: [],
				component_path: "admin/manager/index.vue",
				icon: "el-icon-menu",
				menu_id: 6 - 1,
				name: "Manager",
				path: "/admin/Manager/index.html",
				pid: 6,
				sortid: 1,
				title: "人员列表",
			},
			{

				component_path: "admin/manager/walker.vue",
				menu_id: 7 - 1,
				name: "walkerList",
				path: "/admin/walkerList/index.html",
				pid: 7,
				sortid: 1,
				title: "任务列表",
				children: [],
			},
			{

				component_path: "admin/manager/log.vue",
				menu_id: 7 - 1,
				name: "walkerLogList",
				path: "/admin/walkerLogList/index.html",
				pid: 7,
				sortid: 1,
				title: "巡店日志",
				children: [],
			},
			// {
			//    component_path: "admin/order/index.vue",
			//    menu_id: 7-1,
			//    name: "Order",
			//    path: "/admin/Order/index.html",
			//    pid: 7,
			//    sortid: 1,
			//    title: "巡店管理",
			//    children: [],
			// }, 
		],
	},
	{
		component_path: "admin/aftermarket/index.vue",
		icon: require('@/assets/images/865.png'),
		menu_id: 10,
		name: "Aftermarket",
		path: "/admin/Aftermarket/index.html",
		pid: 0,
		sortid: 1,
		title: "售后管理",
		children: [{
				children: [],
				component_path: "admin/aftermarket/user.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "AfterUser",
				path: "/admin/AfterUser/index.html",
				pid: 10,
				sortid: 9,
				title: "人员列表",
			},
			{
				children: [],
				component_path: "admin/aftermarket/index.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "Aftermarket",
				path: "/admin/Aftermarket/index.html",
				pid: 10,
				sortid: 9,
				title: "售后订单",
			},
			{
				children: [],
				component_path: "admin/aftermarket/evaluation.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "Evaluation",
				path: "/admin/Evaluation/index.html",
				pid: 10,
				sortid: 9,
				title: "评价管理",
			},


			{
				children: [],
				component_path: "admin/aftermarket/set.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "AfterSet",
				path: "/admin/AfterSet/index.html",
				pid: 10,
				sortid: 9,
				title: "售后设置",
			},
			{
				children: [],
				component_path: "admin/aftermarket/bank.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "AfterBank",
				path: "/admin/AfterBank/index.html",
				pid: 10,
				sortid: 9,
				title: "贷款明细统计",
			},
			{
				children: [],
				component_path: "admin/aftermarket/bankCount.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "bankCount",
				path: "/admin/bankCount/index.html",
				pid: 10,
				sortid: 9,
				title: "贷款银行汇总",
			},

			{
				children: [],
				component_path: "admin/loan/index.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "Loan",
				path: "/admin/Loan/index.html",
				pid: 10,
				sortid: 9,
				title: "贷款预审",
			},
			{
				children: [],
				component_path: "admin/aftermarket/policy.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "Policy",
				path: "/admin/Policy/index.html",
				pid: 10,
				sortid: 9,
				title: "售后分单",
			}, {
				children: [],
				component_path: "admin/netsign/index.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "netsign",
				path: "/admin/netsign/index.html",
				pid: 10,
				sortid: 9,
				title: "网签申请",
			},
		]
	},
	// {
	//  	component_path: "admin/department/index.vue",
	//  	icon: require('@/assets/images/icon21.png'),
	//  	menu_id: 10,
	//  	name: "Department",
	//  	path: "/admin/Department/index.html",
	//  	pid: 0,
	//  	sortid: 1,
	//  	title: "组织架构", 
	//  	children: [
	// 			{
	// 			  children: [],
	// 			  component_path: "admin/department/index.vue",
	// 			  icon: "el-icon-menu",
	// 			  menu_id: 10-2,
	// 			  name: "Department",
	// 			  path: "/admin/Department/index.html",
	// 			  pid: 10,
	// 			  sortid: 9,
	// 			  title: "部门列表",
	// 		  },
	// 		  {
	// 			  children: [],
	// 			  component_path: "admin/position/index.vue",
	// 			  icon: "el-icon-menu",
	// 			  menu_id: 10-2,
	// 			  name: "Position",
	// 			  path: "/admin/Position/index.html",
	// 			  pid: 10,
	// 			  sortid: 9,
	// 			  title: "职务列表",
	// 		  },
	//  	],
	// },
	// {
	//  	component_path: "admin/service/index.vue",
	//  	icon: require('@/assets/images/icon11.png'),
	//  	menu_id: 11,
	//  	name: "Service",
	//  	path: "/admin/Service/index.html",
	//  	pid: 0,
	//  	sortid: 1,
	//  	title: "招聘管理", 
	//    children: [
	// 	   {
	// 	     children: [],
	// 	     component_path: "admin/service/index.vue",
	// 	     icon: "el-icon-menu",
	// 	     menu_id: 10-3,
	// 	     name: "Service",
	// 	     path: "/admin/Service/index.html",
	// 	     pid: 10,
	// 	     sortid: 9,
	// 	     title: "招聘列表",
	// 	   },

	// 	   {
	// 	     children: [],
	// 	     component_path: "admin/service/list.vue",
	// 	     icon: "el-icon-menu",
	// 	     menu_id: 10-3,
	// 	     name: "ServiceList",
	// 	     path: "/admin/ServiceList/index.html",
	// 	     pid: 10,
	// 	     sortid: 9,
	// 	     title: "岗位设置",
	// 	   },

	//    ],
	// },

	// {
	// 	 	component_path: "admin/market/index.vue",
	// 	 	icon: require('@/assets/images/icon14.png'),
	// 	 	menu_id: 12,
	// 	 	name: "Market",
	// 	 	path: "/admin/Market/index.html",
	// 	 	pid: 0,
	// 	 	sortid: 1,
	// 	 	title: "门店经营成本", 
	// 	 	children: [
	// 	 		{
	// 	 		   children: [],
	// 	 		   component_path: "admin/market/index.vue",
	// 	 		   menu_id: 12-1,
	// 	 		   name: "Market",
	// 	 		   path: "/admin/Market/index.html",
	// 	 		   pid: 5,
	// 	 		   sortid: 1,
	// 	 		   title: "费用管理",
	// 	 		}, 
	// 			{
	// 			   children: [],
	// 			   component_path: "admin/market/list.vue",
	// 			   menu_id: 12-2,
	// 			   name: "Market1",
	// 			   path: "/admin/Market1/index.html",
	// 			   pid: 5,
	// 			   sortid: 1,
	// 			   title: "门店经营成本",
	// 			}, 



	// 	 	],
	// },

	// {
	// 	component_path: "admin/after/index.vue",
	// 	icon: require('@/assets/images/icon11.png'),
	// 	menu_id: 13,
	// 	name: "After",
	// 	path: "/admin/After/index.html",
	// 	pid: 0,
	// 	sortid: 1,
	// 	title: "商城配送", 
	// 	children:[
	// 		{
	// 		   children: [],
	// 		   component_path: "admin/after/index.vue",
	// 		   menu_id: 13-1,
	// 		   name: "After",
	// 		   path: "/admin/After/index.html",
	// 		   pid: 5,
	// 		   sortid: 1,
	// 		   title: "配送订单",
	// 		}, 


	// 	]

	// },

	{
		component_path: "admin/report/index.vue",
		icon: require('@/assets/images/866.png'),
		menu_id: 1,
		name: "Report",
		path: "/admin/Report/index.html",
		pid: 0,
		sortid: 1,
		title: "战报生成",
		children: [{
			component_path: "admin/report/index.vue",
			icon: require('@/assets/images/866.png'),
			menu_id: 1,
			name: "Report",
			path: "/admin/Report/index.html",
			pid: 0,
			sortid: 1,
			title: "战报生成",
		}]
	},
	// {
	//     component_path: "admin/expense/shop.vue",
	//     icon: require('@/assets/images/ay.png'),
	//     menu_id: 1,
	//     name: "ExpenseShop",
	//     path: "/admin/ExpenseShop/index.html",
	//     pid: 0,
	//     sortid: 1,
	//     title: "门店费用",
	// 	children:[
	// 		{
	// 			component_path: "admin/cost/index.vue",
	// 			menu_id: 1,
	// 			name: "Cost",
	// 			path: "/admin/Cost/index.html",
	// 			pid: 0,
	// 			sortid: 1,
	// 			title: "门店费用管理",

	// 		},
	// 		{
	// 			component_path: "admin/expense/shop.vue",
	// 			menu_id: 1,
	// 			name: "ExpenseShop",
	// 			path: "/admin/ExpenseShop/index.html",
	// 			pid: 0,
	// 			sortid: 1,
	// 			title: "门店列表",

	// 		},
	// 		{
	// 			component_path: "admin/expense/index.vue",
	// 			menu_id: 1,
	// 			name: "Expense",
	// 			path: "/admin/Expense/index.html",
	// 			pid: 0,
	// 			sortid: 1,
	// 			title: "费用更新审核",

	// 		},
	// 	]
	// },

	{
		component_path: "admin/ledger/finance.vue",
		icon: require('@/assets/images/867.png'),
		menu_id: 1,
		name: "Finance",
		path: "/admin/Finance/index.html",
		pid: 0,
		sortid: 1,
		title: "财务管理",
		children: [{
				component_path: "admin/ledger/finance.vue",
				menu_id: 1,
				name: "Finance",
				path: "/admin/Finance/index.html",
				pid: 0,
				sortid: 1,
				title: "营收账单",
			},
			// {
			// 	component_path: "admin/ledger/statement.vue",
			// 	menu_id: 1,
			// 	name: "Statement",
			// 	path: "/admin/Statement/index.html",
			// 	pid: 0,
			// 	sortid: 1,
			// 	title: "分账账单",
			// },

			{
				component_path: "admin/ledger/document.vue",
				menu_id: 1,
				name: "Document",
				path: "/admin/Document/index.html",
				pid: 0,
				sortid: 1,
				title: "收款记录",
			},
			{
				component_path: "admin/ledger/shop.vue",
				menu_id: 1,
				name: "LedgerShop",
				path: "/admin/LedgerShop/index.html",
				pid: 0,
				sortid: 1,
				title: "应收录入",

			},
			{
				component_path: "admin/pay/index.vue",
				menu_id: 1,
				name: "Payment",
				path: "/admin/Payment/index.html",
				pid: 0,
				sortid: 1,
				title: "打款列表",
			}, {
				component_path: "admin/pay/record.vue",
				menu_id: 1,
				name: "Paymentrecord",
				path: "/admin/Payment/record.html",
				pid: 0,
				sortid: 1,
				title: "打款记录",
			},
			{
				component_path: "admin/ledger/withdraw.vue",
				menu_id: 1,
				name: "withdrawIndex",
				path: "/admin/withdraw/index.html",
				pid: 0,
				sortid: 1,
				title: "提现记录",

			},
			{
				component_path: "admin/ledger/index.vue",
				menu_id: 1,
				name: "Ledger",
				path: "/admin/Ledger/index.html",
				pid: 0,
				sortid: 1,
				title: "二手房账单",

			},
			// {
			// 	component_path: "admin/Secondhouse/index.vue",
			// 	menu_id: 1,
			// 	name: "Secondhouse",
			// 	path: "/admin/Secondhouse/index.html",
			// 	pid: 0,
			// 	sortid: 1,
			// 	title: "二手房交易",

			// }, 
			{
				component_path: "admin/ledger/signList.vue",
				menu_id: 1,
				name: "signList",
				path: "/admin/ledger/signList.html",
				pid: 0,
				sortid: 1,
				title: "签约即收佣",
			},
			{
				component_path: "admin/ledger/lease.vue",
				menu_id: 1,
				name: "lease",
				path: "/admin/ledger/lease.html",
				pid: 0,
				sortid: 1,
				title: "租赁收佣",
			},
			{
				component_path: "admin/ledger/transaction.vue",
				menu_id: 1,
				name: "ledgerTransaction",
				path: "/admin/ledger/transaction.html",
				pid: 0,
				sortid: 1,
				title: "租赁交易",
			},
			{
				component_path: "admin/pay/billConfirm.vue",
				menu_id: 1,
				name: "billConfirm",
				path: "/admin/Payment/billConfirm.html",
				pid: 0,
				sortid: 1,
				title: "账单确认记录",
			},
			{
				component_path: "admin/abnormal/cash.vue",
				menu_id: 1,
				name: "Acash",
				path: "/admin/Acash/index.html",
				pid: 0,
				sortid: 1,
				title: "收款审核记录",

			},
		]
	},
	{
		component_path: "admin/statistics/overview.vue",
		icon: require('@/assets/images/869.png'),
		menu_id: 13,
		name: "Statistics",
		path: "/admin/Statistics/index.html",
		pid: 0,
		sortid: 1,
		title: "数据统计",
		children: [{
				children: [],
				component_path: "admin/reportForm/companyCosts.vue",
				menu_id: 13 - 1,
				name: "companyCosts",
				path: "/admin/companyCosts/index.html",
				pid: 5,
				sortid: 1,
				title: "公司成本数据",
			}, {
				children: [],
				component_path: "admin/reportForm/companyCommission.vue",
				menu_id: 13 - 1,
				name: "companyCommission",
				path: "/admin/companyCommission/index.html",
				pid: 5,
				sortid: 1,
				title: "公司佣金数据",
			}, {
				children: [],
				component_path: "admin/reportForm/storeCommission.vue",
				menu_id: 13 - 1,
				name: "storeCommission",
				path: "/admin/storeCommission/index.html",
				pid: 5,
				sortid: 1,
				title: "门店佣金数据",
			}, {
				children: [],
				component_path: "admin/billReport/performanceIndex.vue",
				menu_id: 13 - 1,
				name: "performanceIndex",
				path: "/admin/performanceIndex/index.html",
				pid: 5,
				sortid: 1,
				title: "业绩分成明细表",
			}, {
				children: [],
				component_path: "admin/billReport/transactionIndex.vue",
				menu_id: 13 - 1,
				name: "transactionIndex",
				path: "/admin/transactionIndex/index.html",
				pid: 5,
				sortid: 1,
				title: "我的交易列表",
			}, {
				children: [],
				component_path: "admin/data/operation.vue",
				menu_id: 13 - 1,
				name: "Operation",
				path: "/admin/Operation/index.html",
				pid: 5,
				sortid: 1,
				title: "门店经营数据",
			},
			{
				children: [],
				component_path: "admin/data/total.vue",
				menu_id: 13 - 1,
				name: "Total",
				path: "/admin/Total/index.html",
				pid: 5,
				sortid: 1,
				title: "门店运营数据",
			},
			{
				children: [],
				component_path: "admin/data/coverage.vue",
				menu_id: 13 - 1,
				name: "Coverage",
				path: "/admin/Coverage/index.html",
				pid: 5,
				sortid: 1,
				title: "门店覆盖率",
			},
			// {
			// 	component_path: "admin/ledger/account.vue",
			// 	menu_id: 1,
			// 	name: "LedgerAccount",
			// 	path: "/admin/LedgerAccount/index.html",
			// 	pid: 0,
			// 	sortid: 1,
			// 	title: "分佣统计",

			// },

			// {
			//    children: [],
			//    component_path: "admin/data/bill.vue",
			//    menu_id: 13-1,
			//    name: "Bill",
			//    path: "/admin/Bill/index.html",
			//    pid: 5,
			//    sortid: 1,
			//    title: "分账数据",
			// }, 

			{
				children: [],
				component_path: "admin/data/specialist.vue",
				menu_id: 13 - 1,
				name: "Specialist",
				path: "/admin/Specialist/index.html",
				pid: 5,
				sortid: 1,
				title: "运营专员统计",
			},

			{
				children: [],
				component_path: "admin/data/sales.vue",
				menu_id: 13 - 1,
				name: "Sales",
				path: "/admin/Sales/index.html",
				pid: 5,
				sortid: 1,
				title: "售后专员统计",
			},
			{
				children: [],
				component_path: "admin/data/customer.vue",
				menu_id: 13 - 1,
				name: "Customer",
				path: "/admin/Customer/index.html",
				pid: 5,
				sortid: 1,
				title: "客诉统计",
			},


		]
	},
	// {
	// 	component_path: "admin/banner/index.vue",
	// 	icon: require('@/assets/images/icon1.png'),
	// 	menu_id: 13,
	// 	name: "Banner",
	// 	path: "/admin/Banner/index.html",
	// 	pid: 0,
	// 	sortid: 1,
	// 	title: "奖惩录入", 
	// 	children:[
	// 		{
	// 		   children: [],
	// 		   component_path: "admin/banner/index.vue",
	// 		   menu_id: 13-1,
	// 		   name: "Banner",
	// 		   path: "/admin/Banner/index.html",
	// 		   pid: 5,
	// 		   sortid: 1,
	// 		   title: "奖惩日志列表",
	// 		}, 

	// 	]

	// },

	// {
	// 	 	component_path: "admin/market/index.vue",
	// 	 	icon: require('@/assets/images/icon14.png'),
	// 	 	menu_id: 12,
	// 	 	name: "Market",
	// 	 	path: "/admin/Market/index.html",
	// 	 	pid: 0,
	// 	 	sortid: 1,
	// 	 	title: "费用管理", 
	// 	 	children: [
	// 	 		{
	// 	 		   children: [],
	// 	 		   component_path: "admin/market/index.vue",
	// 	 		   menu_id: 12-1,
	// 	 		   name: "Market",
	// 	 		   path: "/admin/Market/index.html",
	// 	 		   pid: 5,
	// 	 		   sortid: 1,
	// 	 		   title: "门店费用",
	// 	 		}, 
	// 			{
	// 			   children: [],
	// 			   component_path: "admin/market/list.vue",
	// 			   menu_id: 12-2,
	// 			   name: "Market1",
	// 			   path: "/admin/Market1/index.html",
	// 			   pid: 5,
	// 			   sortid: 1,
	// 			   title: "门店成本",
	// 			}, 
	// 	 	],
	// },
	{
		component_path: "admin/system/bill.vue",
		icon: require('@/assets/images/871.png'),
		menu_id: 13,
		name: "System",
		path: "/admin/System/index.html",
		pid: 0,
		sortid: 1,
		title: "系统设置",
		children: [{
				children: [],
				component_path: "admin/region/index.vue",
				menu_id: 13 - 1,
				name: "Region",
				path: "/admin/Region/index.html",
				pid: 5,
				sortid: 1,
				title: "区域管理",
			},
			// {
			//   children: [],
			//   component_path: "admin/friend/index.vue",
			//   icon: "el-icon-menu",
			//   menu_id: 10-2,
			//   name: "Friend",
			//   path: "/admin/Friend/index.html",
			//   pid: 10,
			//   sortid: 9,
			//   title: "组织架构",
			// },
			// {
			//   children: [],
			//   component_path: "admin/walker/index.vue",
			//   icon: "el-icon-menu",
			//   menu_id: 10-2,
			//   name: "Walker",
			//   path: "/admin/Walker/index.html",
			//   pid: 10,
			//   sortid: 9,
			//   title: "店东账号",
			// },
			{
				children: [],
				component_path: "admin/department/index.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "Department",
				path: "/admin/Department/index.html",
				pid: 10,
				sortid: 9,
				title: "组织架构",
			},
			{
				children: [],
				component_path: "admin/position/index.vue",
				icon: "el-icon-menu",
				menu_id: 10 - 2,
				name: "Position",
				path: "/admin/Position/index.html",
				pid: 10,
				sortid: 9,
				title: "职位列表",
			},

			{
				children: [],
				component_path: "admin/role/index.vue",
				menu_id: 13 - 1,
				name: "Role",
				path: "/admin/Role/index.html",
				pid: 5,
				sortid: 1,
				title: "角色管理",
			},
			// {
			// 	component_path: "admin/ledger/shop.vue",
			// 	menu_id: 1,
			// 	name: "LedgerShop",
			// 	path: "/admin/LedgerShop/index.html",
			// 	pid: 0,
			// 	sortid: 1,
			// 	title: "门店应收录入",

			// },
			{
				children: [],
				component_path: "admin/menu/index.vue",
				menu_id: 13 - 1,
				name: "Menu",
				path: "/admin/Menu/index.html",
				pid: 5,
				sortid: 1,
				title: "权限管理",
			},
			{
				children: [],
				component_path: "admin/system/index.vue",
				menu_id: 13 - 1,
				name: "System",
				path: "/admin/System/index.html",
				pid: 5,
				sortid: 1,
				title: "操作日志",
			},

			{
				children: [],
				component_path: "admin/poster/index.vue",
				menu_id: 13 - 1,
				name: "Poster",
				path: "/admin/Poster/index.html",
				pid: 5,
				sortid: 1,
				title: "Banner图",
			},
			{
				children: [],
				component_path: "admin/system/get.vue",
				menu_id: 13 - 1,
				name: "getSetting",
				path: "/admin/getSetting/index.html",
				pid: 5,
				sortid: 1,
				title: "全局设置",
			},
		]
	},
]
export {
	menuData
}